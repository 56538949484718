/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

body {
  background-color: white !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.rst-node {
  min-width: 100%;
  position: relative;
}

.rst-row-wrapper {
  height: 100%;
  box-sizing: border-box;
  cursor: move;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 1;
  }
}

.rst-row-wrapper-drag-disabled {
  cursor: default;
}

.rst-row {
  height: 100%;
  white-space: nowrap;
  display: flex;
  position: relative;

  & > * {
    box-sizing: border-box;
  }
}

.rst-row-landing-pad {
  border: none;
  box-shadow: none;
  outline: none;

  * {
    opacity: 0 !important;
  }

  &::before {
    background-color: lightblue;
    border: 2px dotted black;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}

.rst-row-cancel-pad {
  @extend .rst-row-landing-pad;

  &::before {
    background-color: #e6a8ad;
  }
}

.rst-row-search-match {
  box-shadow: inset 0 -7px 7px -3px #0080ff;
}

.rst-row-search-focus {
  box-shadow: inset 0 -7px 7px -3px #fc6421;
}

%rst-row-item {
  display: inline-block;
  vertical-align: middle;
}

.rst-row-content {
  @extend %rst-row-item;
  position: relative;
  height: 100%;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rst-row-label {
  @extend %rst-row-item;
  flex: 0 1 auto;
  padding-right: 20px;
}

.rst-row-toolbar {
  @extend %rst-row-item;
  flex: 0 1 auto;
  display: flex;
}

.rst-toolbar-btn {
  @extend %rst-row-item;
}

.rst-collapse-btn,
.rst-expand-btn {
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  z-index: 2;
  position: absolute;
  top: 45%;
  width: 30px;
  height: 30px;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    transform-origin: 7px 4px;
    transform: translate3d(-50%, -20%, 0);
    border: solid transparent 10px;
    border-left-width: 7px;
    border-right-width: 7px;
    border-top-color: #d1d5db;
  }

  &:hover::after {
    border-top-color: #6b7280;
  }

  &:focus {
    outline: none;

    // &::after {
    //   filter: drop-shadow(0 0 1px #83bef9) drop-shadow(0 0 1px #83bef9)
    //     drop-shadow(0 0 1px #83bef9);
    // }
  }
}

.rst-expand-btn::after {
  transform: translate3d(-50%, -20%, 0) rotateZ(-90deg);
}

/**
 * Line for under a node with children
 */
.rst-line-children {
  height: 100%;
  display: inline-block;
}

/* ==========================================================================
   Scaffold
    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */
.rst-line-block {
  height: 100%;
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
}

.rst-absolute-line-block {
  @extend .rst-line-block;
  position: absolute;
  top: 0;
}

/* Highlight line for pointing to dragged row destination
   ========================================================================== */
$highlight-color: #36c2f6;
$highlight-line-size: 6px; // Make it an even number for clean rendering

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst-highlight-line-vertical {
  z-index: 3;

  &::before {
    position: absolute;
    content: "";
    background-color: $highlight-color;
    width: $highlight-line-size;
    margin-left: $highlight-line-size / -2;
    left: 50%;
    top: 0;
    height: 100%;
  }

  @keyframes arrow-pulse {
    $base-multiplier: 10;
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    30% {
      transform: translate(0, 30% * $base-multiplier);
      opacity: 1;
    }
    70% {
      transform: translate(0, 70% * $base-multiplier);
      opacity: 1;
    }
    100% {
      transform: translate(0, 100% * $base-multiplier);
      opacity: 0;
    }
  }

  &::after {
    content: "";
    position: absolute;
    height: 0;
    margin-left: -1 * $highlight-line-size / 2;
    left: 50%;
    top: 0;
    border-left: $highlight-line-size / 2 solid transparent;
    border-right: $highlight-line-size / 2 solid transparent;
    border-top: $highlight-line-size / 2 solid white;
    animation: arrow-pulse 1s infinite linear both;
  }
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |  |  |
 * +--+--+
 */
.rst-highlight-top-left-corner {
  &::before {
    z-index: 3;
    content: "";
    position: absolute;
    border-top: solid $highlight-line-size $highlight-color;
    border-left: solid $highlight-line-size $highlight-color;
    box-sizing: border-box;
    height: calc(50% + #{$highlight-line-size / 2});
    top: 50%;
    margin-top: $highlight-line-size / -2;
    right: 0;
    width: calc(50% + #{$highlight-line-size / 2});
  }
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  +->|
 * +-----+
 */
.rst-highlight-bottom-left-corner {
  $arrow-size: 7px;
  z-index: 3;

  &::before {
    content: "";
    position: absolute;
    border-bottom: solid $highlight-line-size $highlight-color;
    border-left: solid $highlight-line-size $highlight-color;
    box-sizing: border-box;
    height: calc(100% + #{$highlight-line-size / 2});
    top: 0;
    right: $arrow-size;
    width: calc(50% - #{$arrow-size - ($highlight-line-size / 2)});
  }

  &::after {
    content: "";
    position: absolute;
    height: 0;
    right: 0;
    top: 100%;
    margin-top: -1 * $arrow-size;
    border-top: $arrow-size solid transparent;
    border-bottom: $arrow-size solid transparent;
    border-left: $arrow-size solid $highlight-color;
  }
}
